export default {
  type: "folders",
  name: "",
  excerpt: "",
  relationshipNames: [
    "folders",
    "organization",
    "tags",
    "allowedLocations",
    "roles",
    "users",
    "parent",
    "serviceCenters",
    "establishments",
    "cafeterias",
  ],
  parent: {
    type: "folders",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  tags: [],
  allowedLocations: [],
  roles: [],
  users: [],
  serviceCenters: [],
  establishments: [],
  cafeterias: [],
};
