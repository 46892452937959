var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"show":_vm.showModal,"modal-classes":"modal-secondary","size":"lg"}},[_c('div',[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper","tabNavClasses":"nav nav-pills nav-fill","value":"global"}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":true}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("FILES.FOLDER"))+" ")]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.folder.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.folder.id || !!_vm.currentFolder.id},on:{"organizationChanged":(organizationId) => {
                _vm.folder.organization.id = organizationId;
                _vm.folder.allowedLocations = [];
                _vm.folder.users = [];
                _vm.folder.roles = [];
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')}`,"placeholder":`${_vm.$t('COMMON.NAME')}`},model:{value:(_vm.folder.name),callback:function ($$v) {_vm.$set(_vm.folder, "name", $$v)},expression:"folder.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{model:{value:(_vm.folder.excerpt),callback:function ($$v) {_vm.$set(_vm.folder, "excerpt", $$v)},expression:"folder.excerpt"}})],1)],1),(_vm.$currentUserCan(_vm.$permissions.PERM_MANAGE_ACCESS_FOLDERS))?_c('tab-pane',{attrs:{"title":"global","id":"2"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("FILES.SHARE"))+" ")]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATIONS')}`,"placeholder":_vm.$t('COMMON.LOCATIONS')}},[_c('locations-selector',{attrs:{"locations":_vm.folder.allowedLocations,"filterable":true,"showAll":false,"disabled":!_vm.folder.organization.id},on:{"locationsChanged":(locations) => {
                _vm.folder.allowedLocations = locations;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ROLES')}`,"placeholder":_vm.$t('COMMON.ROLES')}},[_c('roles-selector',{attrs:{"roles":_vm.folder.roles,"filterable":true,"filterOrganization":_vm.folder.organization.id,"disabled":!_vm.folder.organization.id},on:{"rolesChanged":(roles) => {
                _vm.folder.roles = roles;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.roles}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.USERS')}`,"placeholder":_vm.$t('COMMON.USERS')}},[_c('users-selector',{attrs:{"users":_vm.folder.users,"filterable":true,"filterOrganization":_vm.folder.organization.id,"disabled":!_vm.folder.organization.id},on:{"usersChanged":(users) => {
                _vm.folder.users = users;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.users}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SERVICECENTERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SERVICECENTERS')}`,"placeholder":_vm.$t('COMMON.SERVICECENTERS')}},[_c('service-centers-selector',{attrs:{"serviceCenters":_vm.folder.serviceCenters,"filterable":true,"filterOrganization":_vm.folder.organization.id,"disabled":!_vm.folder.organization.id},on:{"serviceCentersChanged":(serviceCenters) => {
                _vm.folder.serviceCenters = serviceCenters;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.serviceCenters}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ESTABLISHMENTS')}`,"placeholder":_vm.$t('COMMON.ESTABLISHMENTS')}},[_c('establishments-selector',{attrs:{"establishments":_vm.folder.establishments,"filterable":true,"filterOrganization":_vm.folder.organization.id,"disabled":!_vm.folder.organization.id},on:{"establishmentsChanged":(establishments) => {
                _vm.folder.establishments = establishments;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.establishments}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CAFETERIAS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CAFETERIAS')}`,"placeholder":_vm.$t('COMMON.CAFETERIAS')}},[_c('cafeterias-selector',{attrs:{"cafeterias":_vm.folder.cafeterias,"filterable":true,"filterOrganization":_vm.folder.organization.id,"disabled":!_vm.folder.organization.id},on:{"cafeteriasChanged":(cafeterias) => {
                _vm.folder.cafeterias = cafeterias;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cafeterias}})],1):_vm._e()],1)],1),_c('template',{slot:"footer"},[(!_vm.folderData)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.addFolder}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("FILES.ADD_FOLDER"))+" ")]):_vm._e(),(!!_vm.folderData)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.editFolder}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("FILES.EDIT_FOLDER"))+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-link ml-auto",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }