<template>
  <div class="file-manager-container file-manager-grid container-fuild mt-5">
    <div class="row">
      <div class="col-2 mb-4" v-if="folder.id">
        <div class="file-item file-item-folder" @dblclick="navigateToParent()">
          <div class="file-item-content">
            <div class="file-item-icon">
              <i class="fa fa-reply"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="folder in folder.subfolders"
        :key="`folder-${folder.id}`"
        class="col-2 mb-4"
      >
        <div
          class="file-item file-item-folder"
          :class="[
            {
              selected: selectedItems.some(
                (item) => item.id === folder.id && item.type == 'folders'
              ),
              locked: folder.locked,
            },
          ]"
          @dblclick="navigateToFolder(folder.id)"
          @click="selectItem(folder)"
        >
          <el-tooltip :content="folder.name" placement="top">
            <div class="file-item-content">
              <div class="file-item-icon">
                <i class="fa fa-folder"></i>
              </div>
              <div class="file-item-name">
                {{ folder.name }}
              </div>
              <div
                v-if="
                  $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
                  folder.organization
                "
                class="file-item-organization"
              >
                <span class="btn btn-sm btn-info">
                  <i class="ni ni-building"></i>
                  {{ folder.organization.name }}
                </span>
              </div>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div
        v-for="file in folder.files"
        :key="`file-${file.id}`"
        class="col-2 mb-4"
      >
        <div
          class="file-item file-item-folder"
          :class="[
            {
              selected: selectedItems.some(
                (item) => item.id === file.id && item.type == 'files'
              ),
            },
          ]"
          @dblclick="viewFile(file)"
          @click="selectItem(file)"
        >
          <el-tooltip :content="file.name" placement="top">
            <div class="file-item-content">
              <div class="file-item-icon">
                <i class="fa fa-file"></i>
              </div>
              <div class="file-item-name">
                {{ file.name }}
              </div>
              <div
                v-if="
                  $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
                  folder.organization
                "
                class="file-item-organization"
              >
                <span class="btn btn-sm btn-info">
                  <i class="ni ni-building"></i>
                  {{ folder.organization.name }}
                </span>
              </div>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>

    <modal
      @close="closeFileViewer"
      :show="showFileViewer"
      modal-classes="modal-secondary"
      size="xl"
    >
      <div class="text-center mb-5">
        <h2>
          <a
            v-if="$isFileVideo(openedFile)"
            :href="`${openedFile.url}?filename=${openedFile.name}`"
            target="_blank"
          >
            {{ openedFile.name }}
          </a>
          <a
            v-else-if="
              $isFileDocument(openedFile) && openedFile.file_extension === 'pdf'
            "
            :href="`${openedFile.url}?filename=${openedFile.name}&contentDisposition=inline`"
            target="_blank"
          >
            {{ openedFile.name }}
          </a>
        </h2>
      </div>
      <div v-if="$isFileVideo(openedFile)">
        <video
          controls
          :src="`${openedFile.url}?filename=${openedFile.name}&contentDisposition=inline`"
          class="w-100"
          style="max-height: 70vh"
        />
      </div>
      <div
        v-else-if="
          $isFileDocument(openedFile) && openedFile.file_extension === 'pdf'
        "
      >
        <iframe
          ref="pdfIframe"
          :src="`${openedFile.url}?filename=${openedFile.name}&contentDisposition=inline`"
          class="w-100"
          style="height: 80vh"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
export default {
  components: { Modal },

  mixins: [],

  props: {
    folder: {
      type: Object,
      default: null,
      description: "Current folder",
    },
  },

  computed: {},

  watch: {},

  data() {
    return {
      selectedItems: [],
      showFileViewer: false,
      openedFile: {},
    };
  },

  methods: {
    navigateToFolder(id) {
      this.selectedItems = [];
      this.$emit("onNavigateTo", id);
    },

    navigateToParent() {
      if (this.folder.parent) {
        this.navigateToFolder(this.folder.parent.id);
      } else {
        this.navigateToFolder(null);
      }
    },

    selectItem(selectedItem) {
      if (this.selectedItems.some((item) => item.id === selectedItem.id)) {
        this.selectedItems = this.selectedItems.filter(
          (item) =>
            item.id !== selectedItem.id && item.type !== selectedItem.type
        );
      } else {
        this.selectedItems.push(selectedItem);
      }
      this.$emit("onSelectItems", this.selectedItems);
    },

    viewFile(file) {
      if (
        this.$isFileVideo(file) ||
        (this.$isFileDocument(file) && file.file_extension === "pdf")
      ) {
        this.openedFile = file;
        this.showFileViewer = true;
      } else {
        window.open(`${file.url}?filename=${file.name}`, "_blank");
      }
    },

    handleKeyDown(event) {
      if (this.openedFile.file_extension === "pdf") {
        if ((event.ctrlKey || event.metaKey) && event.key === "f") {
          event.preventDefault();
          this.openInNewTab();
        }
      }
    },

    openInNewTab() {
      const iframeUrl = this.$refs.pdfIframe.src;
      window.open(iframeUrl, "_blank");
    },

    closeFileViewer() {
      this.openedFile = {};
      this.showFileViewer = false;
    },
  },
};
</script>

<style lang="sass">
.file-manager-grid
  .file-item
    position: relative
    padding-bottom: 100%
    background-color: var(--gray)
    border-radius: 5px
    text-align: center
    color: #000
    cursor: pointer

    .file-item-organization
      height: 100%
      width: 100%
      position: absolute
      display: flex
      flex-direction: column-reverse
      flex-wrap: nowrap
      align-items: stretch
      justify-content: flex-start

    &.selected
      box-shadow: 0px 0px 10px var(--primary)
      &:before
        content: "\f14a"
        font-family: "Font Awesome 5 Free"
        font-size: 24px
        font-weight: 900
        color: var(--primary)
        position: absolute
        top: 5px
        left: 5px
        transform: translate(-50%, -50%)

    &.locked
      &:not(.selected)
        box-shadow: 0px 0px 2px var(--info)
      &:after
        content: "\f023"
        font-family: "Font Awesome 5 Free"
        font-size: 25px
        font-weight: 900
        color: var(--info)
        position: absolute
        top: 5px
        right: -15px
        transform: translate(-50%, -50%)

    &-content
      position: absolute
      top: 50%
      transform: translateY(-50%)
      left: 0
      width: 100%
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      font-size: 3rem
      user-select: none

      .file-item-icon
        line-height: 0
      .file-item-name
        font-size: 1rem
        line-height: 1rem
        text-overflow: ellipsis
        overflow: hidden
        width: 100%
        padding: 5px
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2
        height: 2.5rem
</style>
